import React, { useEffect, useRef, useState } from 'react';
import styles from './ClipCluster.module.scss';

import useClipsFilters from '../hooks/useClipsFilters';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import ChannelsColumn from './ChannelsColumn/ChannelsColumn';
import ClipColumn from './ClipColumn/ClipColumn';
import CountryColumn from './CountryColumn/CountryColumn';
import ClusterHeader from './ClusterHeader/ClusterHeader';
import ClipAnalytics from './ClipAnalytics/ClipAnalytics';
import { useAppSelector } from 'src/redux/hooks';
import { ChannelGroup } from 'src/Widgets/ChannelGroupsNext/types/types';
import APIClips from 'src/API/APIClips';
import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
import { useTranslation } from 'react-i18next';

const ClipCluster = ({
    profileGroupContent,
    currentProfileGroup,
    clipStates,
    fetchClipStates,
    updateClipState,
    midAirCollisionPopup,
    setMidAirCollisionPopup,
    stateUpdatesObserver,
    wFeedAbortCtrl,
    data,
    filteredData,
    filter,
    selectedDateRange,
    isSearch,
    isEdited,
    isEditorial,
    isMentionFindrApp,
    messageHandler,
    handleCtrlShiftClick,
    tryQuery,
    setIsClipsBackfilled,
    isClipsBackfilled,
    selectedCardElements,
    setSelectedCardElements,
    filterClipsByBackfill,
    externalProfileId,
    setModalType
}: MentionsVisualizerTypes.ClipClusterProps) => {
    const processedIndices: Set<number> = new Set();

    const [fetchedItems, setFetchedItems] = useState<Set<number>>(new Set());
    const [clipDetail, setClipDetail] = useState<MentionsVisualizerTypes.ClipDetailType>(
        {} as MentionsVisualizerTypes.ClipDetailType
    );
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [backFillSelectionVisible, setBackFillSelectionVisible] = useState(false);

    const channelGroups = useAppSelector((state) => state.channelGroupReducers);

    const {t} = useTranslation();

    const {
        activeFilter,
        setActiveFilter,
        handleChannelFilter,
        handleCountryFilter,
        handleProfileFilter,
        handleSearchChannels,
        handleTypeFilter,
        hasTypeInGroup,
        hasTypeInProfile,
        handleLanguageFilter,
        handleSentimentFilter,
        searchChannelsInput,
        setSearchChannelsInput,
        filteredChannelIcons,
        setFilteredChannelIcons,
        filteredDataToDisplay,
        listKey,
        applyVisualizerFilters,
        hasSentiment,
        sortedUniqueChannels,
        sortedUniqueCountries,
        getLanguagesByCountry,
        selectedLanguages,
        resetFilters
    } = useClipsFilters({
        profileGroupContent,
        setClipDetail,
        setFetchedItems,
        processedIndices,
        isEditorial,
        isClipsBackfilled,
        selectedCardElements,
        externalProfileId
    });

    const notifIds = filteredDataToDisplay
    .flatMap((clip: MentionsVisualizerTypes.Clip) => 
        [clip.notifID, ...(clip.duplicates?.map((dup: MentionsVisualizerTypes.Clip) => dup.notifID) || [])]
    ).filter((notifId: string, index: number, self: string[]) => self.indexOf(notifId) === index)
    .join(',');

    //fetch again when active filter changes
    useEffect(() => {
        applyVisualizerFilters();
    }, [activeFilter, selectedLanguages]);

    //fetch again when data changes in search
    useEffect(() => {
        if (isSearch) {
            applyVisualizerFilters();
        }
    }, [data]);

    useEffect(() => {
        if (isEditorial) {
            applyVisualizerFilters();
        }
    }, [filteredData]);

    useEffect(() => {
        if(isClipsBackfilled){
            //reset filters when backfill is done
            resetFilters();
        }
    }, [isClipsBackfilled]);

    // Reset profile filter when the profile group changes if externalProfileId is not in the new profile group
    useEffect(() => {
        if (
            externalProfileId &&
            !filter.profileGroup.profiles.some(
                (profile: MentionsVisualizerTypes.ProfileTypes) =>
                    profile.id === externalProfileId
            )
        ) {
            setActiveFilter((prev) => ({
                ...prev,
                profile: null
            }));
        }
    }, [currentProfileGroup]);

    useEffect(() => {
        if (isEditorial) {
            fetchClipStates(notifIds);
        }
    }, []);

    const messageHandlerListen = (event: MessageEvent) => { 
        const { type, data } = event;
        const clipDetailData = clipDetail[data.payload.notificationId];

        const clipEditorialState = clipStates?.states?.find(
            (state: { id: string; state: string }) => Number(state.id) === data.payload.notificationId
        ) || { state: '' , id: ''};
        // If the type is set-updates, update the clip state accordingly
        if (type === 'message' && data.type === 'set-updates' && clipDetailData.notifid === data.payload.notificationId && data.payload.action) {
            // update the clip state with data.payload.action
            updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, data.payload.action, data.payload.editorialData);
        }
        // If the type is get-updates, update the clip state accordingly
        else if (type === 'message' && data.type === 'get-updates') {
            console.log('sending set-updates', event, clipEditorialState.state)
            console.log(messageHandler?.getWindow())
            messageHandler?.send('editorial', 'set-updates', {
                notificationId: clipDetailData.notifid,
                clipState: clipEditorialState.state,
                headline: clipEditorialState.headline,
                summary: clipEditorialState.summary,
                userid: clipEditorialState.userid,
                sentiment: clipEditorialState.sentiment
            });
        } else {
            return;
        }
        console.log('processed event', event);
    }

    const addMessageListener = () => {
        // Listen to editorial messages
        messageHandler?.listen('editorial', messageHandlerListen);
    }

    useEffect(() => {
        if(isEditorial){
            messageHandler?.removeListener('editorial', messageHandlerListen);
            addMessageListener();
        }
    },[clipStates, clipDetail])

    useEffect(() => {
        if (isEditorial) {
            return () => {
                messageHandler?.removeListener('editorial', messageHandlerListen);
                wFeedAbortCtrl.current.abort();
            }
        }
    }, []);
    
    const checkChannelGroup = (
        channelNumber: number,
        selectedChannelGroup: MentionsVisualizerTypes.SelectedChannelGroup
    ): string => {
        let channelGroupTitle = '';
        channelGroups.forEach((channelGroup: ChannelGroup) => {
            if (
                channelGroup.channels.filter((channel) => channel.id === channelNumber)
                    .length > 0 &&
                selectedChannelGroup?.value === channelGroup.id
            ) {
                channelGroupTitle = channelGroup.title;
            }
        });
        return channelGroups && filter.selectedChannelGroup ? channelGroupTitle : '';
    };

    return (
        <div className={styles.clipClusterContainer} style={{}}>
            {/* @ts-ignore */}
            {midAirCollisionPopup && <Popup 
                onOk={() => setMidAirCollisionPopup(false)}
                size='auto'
                blockContent={true}
            >
                <div>
                    <h3>{t('Mid-air collision')}</h3>
                    <p>{t('Update rejected - item was changed in the meantime')}</p>
                </div>
                </Popup>}
            <ClusterHeader
                profileGroupContent={profileGroupContent}
                filteredDataToDisplay={filteredDataToDisplay}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                handleProfileFilter={handleProfileFilter}
                selectedDateRange={selectedDateRange}
                isSearch={isSearch}
                isEditorial={isEditorial}
                isEdited={isEdited}
                tooltipVisible={tooltipVisible}
                setTooltipVisible={setTooltipVisible}
                backFillSelectionVisible={backFillSelectionVisible}
                setBackFillSelectionVisible={setBackFillSelectionVisible}
                selectedChannelGroup={checkChannelGroup(
                    filteredDataToDisplay.length > 0
                        ? filteredDataToDisplay[0].channelNumber
                        : 0,
                    filter.selectedChannelGroup
                )}
                tryQuery={tryQuery}

            />
            <div className={styles.clusterMiddleContainer}>
                <ChannelsColumn
                    hasTypeInGroup={hasTypeInGroup}
                    hasTypeInProfile={hasTypeInProfile}
                    hasSentiment={hasSentiment}
                    handleTypeFilter={handleTypeFilter}
                    handleSentimentFilter={handleSentimentFilter}
                    handleSearchChannels={handleSearchChannels}
                    handleChannelFilter={handleChannelFilter}
                    profileGroupContent={profileGroupContent}
                    searchChannelsInput={searchChannelsInput}
                    setSearchChannelsInput={setSearchChannelsInput}
                    setFilteredChannelIcons={setFilteredChannelIcons}
                    sortedUniqueChannels={sortedUniqueChannels}
                    setActiveFilter={setActiveFilter}
                    activeFilter={activeFilter}
                    listKey={listKey}
                    isEdited={isEdited}
                    isEditorial={isEditorial}
                    filteredChannelIcons={filteredChannelIcons}
                />
                <ClipColumn
                    listKey={listKey}
                    filteredDataToDisplay={filteredDataToDisplay}
                    isSearch={isSearch}
                    isEditorial={isEditorial}
                    fetchedItems={fetchedItems}
                    setFetchedItems={setFetchedItems}
                    clipDetail={clipDetail}
                    setClipDetail={setClipDetail}
                    isEdited={isEdited}
                    activeFilter={activeFilter}
                    isMentionFindrApp={isMentionFindrApp}
                    backFillSelectionVisible={backFillSelectionVisible}
                    setBackFillSelectionVisible={setBackFillSelectionVisible}
                    clipStates={clipStates}
                    updateClipState={updateClipState}
                    fetchClipStates={fetchClipStates}
                    stateUpdatesObserver={stateUpdatesObserver}
                    wFeedAbortCtrl={wFeedAbortCtrl}
                    messageHandler={messageHandler}
                    tryQuery={tryQuery}
                    setIsClipsBackfilled={setIsClipsBackfilled}
                    selectedCardElements={selectedCardElements}
                    setSelectedCardElements={setSelectedCardElements}
                    filterClipsByBackfill={filterClipsByBackfill}
                    setModalType={setModalType}
                    handleCtrlShiftClick={handleCtrlShiftClick}
                />
                <CountryColumn
                    handleCountryFilter={handleCountryFilter}
                    handleLanguageFilter={handleLanguageFilter}
                    getLanguagesByCountry={getLanguagesByCountry}
                    sortedUniqueCountries={sortedUniqueCountries}
                    activeFilter={activeFilter}
                    selectedLanguages={selectedLanguages}
                />
                <ClipAnalytics
                    selectedDateRange={selectedDateRange}
                    setTooltipVisible={setTooltipVisible}
                    tooltipVisible={tooltipVisible}
                    profileGroupContent={profileGroupContent}
                    activeFilter={activeFilter}
                    isEdited={isEdited}
                    isSearch={isSearch}
                />
            </div>
        </div>
    );
};

export default ClipCluster;
