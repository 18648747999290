import React, { useEffect, useMemo, useRef, useState } from 'react'
import APIClips from 'src/API/APIClips';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import { useAppSelector } from 'src/redux/hooks';

const useFetchEditorialStates = (data: MentionsVisualizerTypes.Clip[], isEditorial?: boolean) => {

    const me = useAppSelector((state) => state.me);
    const observerRef = useRef<NodeJS.Timeout | null>(null);
    const wFeedAbortCtrl = useRef(new AbortController());

    const [isClipStateUpdated, setIsClipStateUpdated] = useState(false);
    const [isStateUpdateRunning, setIsStateUpdateRunning] = useState(false);
    const [midAirCollisionPopup, setMidAirCollisionPopup] = useState(false);
    const [clipStates, setClipStates] = useState<any>({
        states: [],
        cl: 0,
        rc: 0,
        msg: ''
      });

          // Function to observe state updates in real-time
    const stateUpdatesObserver = async (cl: number) => {
        console.log('stateUpdatesObserver called with cl:', cl);
        if (isStateUpdateRunning) {
            console.log('Observer already running, exiting');
            return; // Prevent overlapping calls
        }
        setIsStateUpdateRunning(true); // Set isRunning to true
    
        try {
            const res = await APIClips.clipStatesUpdateObserver(cl, wFeedAbortCtrl.current.signal, true);
    
            if (res.rc === 0) {
                
                console.log('State update response received:', res);
                setClipStates((prevClipStates: any) => ({
                    ...prevClipStates,
                    states: prevClipStates.states.map((stateItem: any) => {
                        const updatedState = res.states[Number(stateItem.id)];
                        return updatedState
                            ? { ...stateItem, ...updatedState }
                            : stateItem;
                    })
                }));
            }
            if (
                res.rc === 0 ||
                (Array.isArray(res) && res.length === 0) // api returns empty array when no status changed and timeout reached - also sending a new call in this case
            ) {
                
                if (!isStateUpdateRunning && isEditorial) {
                    console.log('Setting up new observer');
                    setTimeout(() => {
                        stateUpdatesObserver(cl);
                    }, 250);
                }
            } else {
                console.error('State update response error:', res);
            }
        } catch (error) {
            console.error('Error in stateUpdatesObserver, retrying in 30 seconds:', error);
            if (observerRef.current) {
                clearTimeout(observerRef.current);
            }
            observerRef.current = setTimeout(() => {
                isEditorial && stateUpdatesObserver(cl);
            }, 30000);  // Retry after 30 seconds
        } finally {
            setIsStateUpdateRunning(false); // Reset isRunning when the call completes
        }
    };

    // Editorial Workflow State Updater
    const updateClipState = async (notifID: string, cas: number, cl: number, action: string, data?: { [key: string]: string }) => {
        if (isClipStateUpdated) {
            console.log('Update already in progress, skipping this call'); // Debugging log
            return;
        }
        setIsClipStateUpdated(true);
        const res = await APIClips.updateClipState(notifID, cas, cl, action, data, [22029]);


        if (res.rc === 0) {
            if (action !== 'take') {
                setIsClipStateUpdated(false);
                return;
            }

            // change state here immedeiately to make sure it's already changed when mediaEditor is asking for it
            setClipStates((prevClipStates:any) => {
                const clipIndex = clipStates.states.findIndex((clip: any) => clip.id == notifID);
                const updatedClip = {...prevClipStates.states[clipIndex], state:'locked', userid: me.name}
                let updatedStates = [...prevClipStates.states];
                updatedStates[clipIndex] = updatedClip;
                return {
                    ...prevClipStates,
                    states: updatedStates
                }
            })
            console.log('State updated successfully, running observer');
            //stateUpdatesObserver(cl);  // Run state observer after state update
        } else if (res.rc === 22029) {
            setMidAirCollisionPopup(true);
        } else {
            console.error('State update failed');
        }
        setIsClipStateUpdated(false);
    };

    const handleCtrlShiftClick = async (e: KeyboardEvent, clipEditorialState: any, clipDetailData: any, clipStates: any) => {
        // Check for Shift and Ctrl keys and only perform the state update
        if (e.shiftKey) {
            if (
                (clipEditorialState.state === 'locked' &&
                    me?.name === clipEditorialState.userid) ||
                ['rejected', null].includes(clipEditorialState.state)
            ) {
                // Set state to "duplicate"
                await updateClipState(
                    clipDetailData.notifid,
                    clipEditorialState.cas,
                    clipStates.cl,
                    'dup'
                );
            } else if (clipEditorialState.state === 'dup') {
                await updateClipState(
                    clipDetailData.notifid,
                    clipEditorialState.cas,
                    clipStates.cl,
                    'giveback'
                );
            }
        } else if (e.ctrlKey) {
            if (
                (clipEditorialState.state === 'locked' &&
                    me?.name === clipEditorialState.userid) ||
                ['dup', null].includes(clipEditorialState.state)
            ) {
                // Set state to "reject"
                await updateClipState(
                    clipDetailData.notifid,
                    clipEditorialState.cas,
                    clipStates.cl,
                    'reject'
                );
            } else if (clipEditorialState.state === 'rejected') {
                await updateClipState(
                    clipDetailData.notifid,
                    clipEditorialState.cas,
                    clipStates.cl,
                    'giveback'
                );
            }
        }
    };

    // Ensure that the observer is cleared when the component is unmounted
    useEffect(() => {
        if (isEditorial && data.length > 0 && !isStateUpdateRunning) {
            clipStates.cl && stateUpdatesObserver(clipStates.cl);
        }
        return () => {
            if (observerRef.current) {
                clearTimeout(observerRef.current);
            }
        };
    }, [clipStates.states]);

     //Editorial Workflow States Fetcher gets notfiIds seperated by commas
     const fetchClipStates = async (notifIds: string) => {
        if (!notifIds) return;
    
        try {
            const notifIdArray = String(notifIds).split(',');
            let currentCl = clipStates.cl;
            let accumulatedRes = { 
                states: {} as Record<string, any>, 
                cl: currentCl, 
                rc: 0, 
                msg: '' 
            };
    
            // Process in chunks of 500
            for (let i = 0; i < notifIdArray.length; i += 1000) {
                const chunk = notifIdArray.slice(i, i + 1000);
                const chunkRes = await APIClips.getClipsStates(chunk.join(','));
    
                if (chunkRes.rc !== 0) {
                    console.error('Error fetching clip states:', chunkRes.msg);
                    accumulatedRes.rc = chunkRes.rc; // Capture error from failing chunk
                    break;
                }
    
                // Merge states from this chunk
                Object.assign(accumulatedRes.states, chunkRes.states);
                
                // Update control values from last successful chunk
                accumulatedRes.cl = chunkRes.cl
                accumulatedRes.rc = chunkRes.rc;
                accumulatedRes.msg = chunkRes.msg;
            }
    
            if (accumulatedRes.rc !== 0) return;
    
            // Convert accumulated states to array
            const newStatesArray = Object.values(accumulatedRes.states);
    
            // Merge states with existing
            setClipStates((prevClipStates: any) => {
                const stateMap = new Map(prevClipStates.states?.map((s: any) => [s.id, s]) || []);
                newStatesArray.forEach((state: any) => stateMap.set(state.id, state));
                
                return {
                    ...prevClipStates,
                    cl: accumulatedRes.cl || prevClipStates.cl,
                    msg: accumulatedRes.msg,
                    rc: accumulatedRes.rc,
                    states: Array.from(stateMap.values())
                };
            });
        } catch (error) {
            console.error('Error fetching clip states:', error);
        }
    };

    const stateMap = useMemo(() => {
        const map = new Map<string, any>(); // Key type changed to string
        clipStates?.states?.forEach((clipState: any) => {
          map.set(clipState.id.toString(), clipState.state); // Use string keys
        });
        return map;
      }, [clipStates?.states]);
  
      const editorialData = useMemo(() => (
        data.map(clip => ({
          ...clip,
          state: stateMap.get(clip.notifID.toString()) ?? null // Ensure notifID is a string
        }))
      ), [data, stateMap]);

  return {
    fetchClipStates,
    clipStates,
    setClipStates,
    editorialData,
    updateClipState,
    midAirCollisionPopup,
    setMidAirCollisionPopup,
    stateUpdatesObserver,
    wFeedAbortCtrl,
    handleCtrlShiftClick
  }
}

export default useFetchEditorialStates